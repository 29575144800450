/*
    Generated on 29/05/2024 by UI Generator PRICES-IDE
    https://amanah.cs.ui.ac.id/research/ifml-regen
    version 3.3.2
*/
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  MultiSelectField,
  TextAreaField,
  VisualizationAttr,
  Spinner,
} from 'commons/components'
import {
  ALLOWED_PERMISSIONS,
  findAllowedPermission,
} from 'commons/constants/allowedPermission'
import cleanFormData from 'commons/utils/cleanFormData'

import saveViaPaymentGateway from '../services/saveViaPaymentGateway'

import { ToasterError } from 'commons/components'
import * as Layouts from 'commons/layouts'

const FormPilihPayment = ({}) => {
  const { control, watch, handleSubmit } = useForm()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const options = [
    { id: 'invoice', name: 'Invoice' },
    { id: 'virtualaccount', name: 'Virtual Account' },
  ]

  const kirim = data => {
    const paymentMethod = encodeURIComponent(data.paymentMethod)
    const currentUrl = 'viapaymentgateway/paymentchoice'
    console.log(data.paymentMethod)

    const url = new URL(currentUrl, window.location.origin)
    const searchParams = new URLSearchParams(url.search)

    searchParams.append('id', id)
    searchParams.append('paymentMethod', paymentMethod)

    navigate(
      `/viapaymentgateway/${paymentMethod}/add?${searchParams.toString()}`
    )
  }

  return (
    <Layouts.FormComponentLayout
      title="Tambah PaymentGateway"
      onSubmit={handleSubmit(kirim)}
      vas={[]}
      formFields={[
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field, fieldState }) => (
            <SelectionField
              label="Payment Method"
              placeholder="Masukkan Payment Method"
              options={options}
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,

        // adiet ada tambahan 2 atribut baru ya
        // int quantity = ((Double) vmjExchange.getRequestBodyForm("quantity")).intValue();
        // int pricePerItem = ((Double) vmjExchange.getRequestBodyForm("price_per_item")).intValue();
      ]}
      itemsEvents={[
        <Button type="submit" variant="primary">
          Kirim
        </Button>,
      ]}
    />
  )
}

export default FormPilihPayment
